<template>
  <v-dialog v-model="showModal" max-width="1200px" fullscreen transition="dialog-bottom-transition">
    <v-card tile>
      <v-card-title class="text-h6 white--text px-2 py-1 primary justify-space-between">
        <v-spacer></v-spacer>
        出荷管理システム（仮）
        <v-spacer></v-spacer>
        <v-btn text icon color="white" @click="handleClose">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-col cols="12" sm="2">
          <v-tabs vertical v-model="shippingSeqNumber" active-class="v-tab-active-custom">
            <v-tab v-for="(row, index) in shippingSeqNumbers" :key="index" :href="`#${row}`">
              {{ row }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" sm="10">
          <v-tabs v-model="model" centered slider-color="primary" grow>
            <v-tab v-for="tab in tabs" :key="tab" :href="`#${tab.type}`">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-card-actions>
      <v-card-actions>
        <v-col cols="12" offset="2" sm="10" class="pl-0" :key="pdfUrl">
          <pdf v-for="page in pdfNumPages" :src="pdfData" :page="page" :key="page" />
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import pdf from 'pdfvuer';
export default {
  components: {
    pdf,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    shippingSeqNumbers: Array,
  },
  emits: ['close', 'click:confirm'],
  data() {
    const tabs = [
      {
        name: 'Invoice',
        type: 0,
      },
      {
        name: 'Details',
        type: 1,
      },
      {
        name: '梱包明細',
        type: 2,
      },
      {
        name: '出荷',
        type: 3,
      },
    ];
    return {
      tabs: tabs,
      shippingSeqNumber: '',
      model: tabs[0].type,
      pdfUrl: null,
      pdfData: null,
      pdfNumPages: 1,
    };
  },
  watch: {
    model(newValue, oldValue) {
      if (!oldValue) return;
      this.getPdfData(this.shippingSeqNumber, this.model);
    },
    shippingSeqNumber() {
      this.getPdfData(this.shippingSeqNumber, this.model);
    },
    showModal(val) {
      if (val) {
        if (this.shippingSeqNumbers.length > 0) {
          this.shippingSeqNumber = this.shippingSeqNumbers[0];
          this.model = this.tabs[0].type;
        }
      } else {
        this.shippingSeqNumber = null;
        this.pdfUrl = null;
        this.pdfData = null;
        this.model = null;
      }
    },
  },
  methods: {
    ...mapActions({
      downloadMergedPdf: 'api/downloadMergedPdf',
    }),
    handleClose() {
      this.$emit('close');
    },
    async getPdfData(shippingSeqNumber, type) {
      if (!shippingSeqNumber || !this.showModal) return;
      this.pdfData = null;
      this.pdfUrl = await this.downloadMergedPdf({
        shippingSeqNumber: shippingSeqNumber,
        type: type,
      });
      this.pdfData = pdf.createLoadingTask(this.pdfUrl);
      this.pdfData.then((result) => {
        this.pdfNumPages = result.numPages;
      });
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
.v-tab-active-custom {
  background: #b1d1eb;
}
</style>
